<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="device">
  <div class="page w-100">

    <div class="group col-12 col-xl-12 col-lg-12">

      <div class="my-4">
        <div class="row align-items-center">
          <div class="col-xl-8">
            <h1><b>{{'modules.devices.device-detail' | translate}}</b> {{ device.Name }}</h1>
          </div>
          <div class="col-xl-4 text-xl-end">
            <div class="btn btn-primary m-2" [routerLink]="['/devices/' + device!.Id  + '/alarms/add']">
              {{'modules.alarms.add-alarm' | translate}}
            </div>
            <!-- <div *ngIf="isAdmin && device.IdUser != authSrv.currentUserValue!.UserId" class="btn btn-primary m-2" [routerLink]="['/devices/' + device!.Id  + '/detail']">
              {{'modules.devices.edit-device' | translate}}
            </div>-->

            <div  class="btn btn-primary m-2" (click)="editDevice()">
              {{'modules.devices.edit-device' | translate}}
            </div>
            <app-generic-button *ngIf="isAdmin"  [hasModal]="false" txtBtn="{{'modules.devices.migrate-data' | translate}}" classType="warning"
            (confirmDelete)="migrateDevice()" ></app-generic-button>
            <app-generic-button *ngIf="isAdmin" [hasModal]="false" txtBtn="{{'modules.devices.clear-device' | translate}}" classType="danger"
            (confirmDelete)="cleanDevice()" ></app-generic-button>

          </div>
        </div>
      </div>

      <app-block-dashboard>

        <mat-tab-group [selectedIndex]="currentIndex" (selectedTabChange)="onTabChanged($event);"
         mat-align-tabs="start"  style="width:100vw;">

          <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.shared.general' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-xl-4" >
                  <div class="mb-4">
                    <app-configuration-detail-device [device]="device" [information]="lastInformationSensors" ></app-configuration-detail-device>
                  </div>
                  <div class="mb-4" >
                    <app-map-device
                    [lat]="latitudeWGS84"
                    [lng]="longitudeWGS84">
                  </app-map-device>

                  </div>
                </div>
                <div class="col-xl-8">
                  <div class="mb-4">
                    <app-sensor-informations-detail-device  [sensors]="lastInformationSensors" ></app-sensor-informations-detail-device>

                  </div>
                </div>
              </div>

            </div>
          </mat-tab>
          <mat-tab >
            <ng-template mat-tab-label>
              {{'modules.shared.chart' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12" *ngIf="lastInformationSensors == null">
                  <strong>{{'modules.devices.no-data' | translate}}.</strong>
                </div>
                <div style="min-height: 400px;" class="col-12" *ngIf="lastInformationSensors" >

                  <div *ngIf="device && ( chartLoad == false)" style="height: 300px">
                    <app-loading-custom [loading]="true"></app-loading-custom>
                  </div>

                  <app-timeline-apex-chart *ngIf="device && ( currentIndex == 1 || chartLoad)"
                    [idAlert]="idAlert"
                    [device]="device"
                    [showTitle]="false" >
                  </app-timeline-apex-chart>
                  <!-- <app-timeline-apex-test-chart *ngIf="device && ( currentIndex == 1 || chartLoad)"
                    [idAlert]="idAlert"
                    [device]="device"
                    [showTitle]="false" >
                  </app-timeline-apex-test-chart> -->

                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="showTrips">
            <ng-template mat-tab-label>
              {{'modules.shared.trips' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <app-list-trips-devices *ngIf="device" [device]="device"  ></app-list-trips-devices>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.notifications.notifications' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12">

              <app-list-notifications #notificationsList [isManufacturer]="authSrv.isManufacturer()"  [isClient]="authSrv.isClient()"
              nameDatatable="notificationsNoProcessed" [device]="device"  ></app-list-notifications>
                  <!-- <app-list-device-alarms [deviceInput]="device" ></app-list-device-alarms> -->

                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.alarms.alarms' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12">
                  <app-list-all-alarms [deviceInput]="device" [showDefaultValuesAlarm]="false"></app-list-all-alarms>
                  <!-- <app-list-device-alarms [deviceInput]="device" ></app-list-device-alarms> -->

                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" >
            <ng-template mat-tab-label>
              {{'modules.users.user' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12">
                  <div *ngIf="device.User == null">
                    {{'modules.users.no-user' | translate}}.
                  </div>
                  <div class="main main-box" *ngIf="device.User">
                    <div class="row">
                      <div class="mb-3" [ngClass]="(device.User!.FirstName && device.User!.FirstName != '')?'col-md-6':'col-md-12'">
                        <div><strong>{{'modules.tables.name' | translate}}: </strong></div>
                        <div><a [routerLink]="['/users/' + device.User!.Id + '/detail' ]" class="c-pointer">{{ device.User!.Name??'' }}</a></div>
                      </div>
                      <div *ngIf="device!.User!.FirstName && device.User.FirstName != ''" class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.surnames' | translate}}: </strong></div>
                        <div>{{ device.User.FirstName }} {{ device!.User!.LastName??'' }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.email2' | translate}}: </strong></div>
                        <div>{{ device.User!.Email??'modules.shared.no-info' | translate }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.phone' | translate}}: </strong></div>
                        <div>{{ device.User!.Phone1??'modules.shared.no-info' | translate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3 text-xl-end">
                  <div *ngIf="device.User == null && ( authSrv.isAdmin() || authSrv.isManufacturer())" class="btn btn-primary" (click)="changeUser('user')">
                    {{'modules.users.associate-user' | translate}}
                  </div>
                  <div *ngIf="device.User != null  && ( authSrv.isAdmin() || authSrv.isManufacturer())" class="btn btn-primary" (click)="changeUser('user', device.User)">
                    {{'modules.users.change-user' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="authSrv.isAdmin() == true || authSrv.isClient() == true">
            <ng-template mat-tab-label>
              {{'modules.tables.account' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="main main-box" *ngIf="device.Manufacturer">
                    <div class="row">
                      <div class="mb-3" [ngClass]="(device.Manufacturer!.FirstName && device.Manufacturer!.FirstName != '')?'col-md-6':'col-md-12'">
                        <div><strong>{{'modules.tables.name' | translate}}: </strong></div>
                        <div><a [routerLink]="['/users/' + device.Manufacturer!.Id + '/detail' ]" class="c-pointer">{{ device.Manufacturer!.Name??'' }}</a></div>
                      </div>
                      <div class="col-md-6 mb-3" *ngIf="device!.Manufacturer!.FirstName && device.Manufacturer.FirstName != ''">
                        <div><strong>{{'modules.tables.surnames' | translate}}: </strong></div>
                        <div>{{ device.Manufacturer!.FirstName??'' }} {{ device.Manufacturer!.LastName??'' }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.email2' | translate}}: </strong></div>
                        <div>{{ device.Manufacturer!.Email??'modules.shared.no-info' | translate }}</div>
                      </div>
                      <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.phone' | translate}}: </strong></div>
                        <div>{{ device.Manufacturer!.Phone1??'modules.shared.no-info' | translate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="authSrv.isAdmin()">
            <ng-template mat-tab-label>
              {{'modules.devices.data' | translate}}
            </ng-template>
            <div class="mt-5 mb-3 container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive" *ngIf="device!.Informations">
                    <table class="table table-striped  w-100" style="width: 100%;">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>{{'modules.tables.date' | translate}}</th>
                          <th>Ip</th>
                          <th>{{'modules.devices.trace' | translate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="device?.Informations!.length == 0" >
                          <td colspan="4">{{ 'modules.shared.no-info' | translate }}</td>
                        </tr>
                        <tr *ngFor="let information of device?.Informations!.slice().reverse() | slice:0:20;" >
                          <td>#{{ information.Id }}</td>
                          <td>{{ information.TrackPosition!.Date  | date: "dd-MM-yyyy" }} {{ 'modules.trips.date-aux' | translate }} {{ information.TrackPosition!.Date  | date: "HH:mm:ss" }}</td>
                          <td>{{ information.Ip??'modules.shared.without-info' | translate }}</td>
                          <td>{{ information.Trace??'modules.shared.without-info' | translate }}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>


              </div>


            </div>
          </mat-tab>

        </mat-tab-group>



        <!--
        <div class="table-responsive" *ngIf="device.DeviceModel">
          <table class="table table-striped  w-100" style="width: 100%;">
            <tbody>
              <tr>
                <td>{{'modules.tables.name' | translate}}: </td>
                <td>{{ device.Name }}</td>
              </tr>
              <tr>
                <td>{{'modules.alarms.model' | translate}}: </td>
                <td class="h2withdiv"><a [routerLink]="['/devices/models/' + device.DeviceModel.Id + '/detail' ]">{{
                    device.DeviceModel.Name }}</a>

                    <div *ngIf="device.Manufacturer == null && ( authSrv.isAdmin() || authSrv.isManufacturer())" class="text-right-label" style="flex-direction: row-reverse"  (click)="changeModel('fabricant')">
                      <span class="c-pointer">{{'modules.devices.associate-model' | translate}}</span>
                    </div>
                    <div *ngIf="device.Manufacturer  && ( authSrv.isAdmin() || authSrv.isManufacturer())" class="text-right-label" style="flex-direction: row-reverse" (click)="changeModel('fabricant', device.Manufacturer)">
                      <span class="c-pointer">{{'modules.devices.change-model' | translate}}</span>
                    </div>
                  </td>
              </tr>
              <tr *ngIf="authSrv.isClient()">
                <td>{{'modules.devices.manufacturer' | translate}}: </td>
                <td><a [routerLink]="['/users/' + device.Manufacturer!.Id + '/detail' ]" class="c-pointer">{{
                  device.Manufacturer!.Name??'' }}</a></td>
              </tr>
              <tr *ngIf="authSrv.isClient()">
                <td>{{'modules.tables.email-manufacturer' | translate}}: </td>
                <td>{{ device.Manufacturer!.Email??'' }}</td>
              </tr>
              <tr *ngIf="authSrv.isClient()">
                <td>{{'modules.tables.phone-manufacturer' | translate}}: </td>
                <td>{{ device.Manufacturer!.Phone1??'' }}</td>
              </tr>
              <tr>
                <td>{{'modules.tables.register-date' | translate}}: </td>
                <td>{{ device.CreatedAt | date: "HH:mm - dd/MM/yyyy" }}</td>
              </tr>
              <tr *ngIf="device.UpdatedAt">
                <td>{{'modules.tables.last-modification' | translate}}:</td>
                <td>{{ device.UpdatedAt | date: "HH:mm - dd/MM/yyyy" }}</td>
              </tr>

              <tr>
                <td colspan="2" *ngIf="device.Active == true">{{'modules.tables.device-enabled' | translate}}.</td>
                <td colspan="2" *ngIf="device.Active == false">{{'modules.tables.device-disabled' | translate}}.</td>
              </tr>

              <tr>
                <td colspan="2">{{'modules.tables.device-has' | translate}}  {{ device.Informations?.length??0 }} {{'modules.tables.records' | translate}}.</td>
              </tr>
            </tbody>
          </table>
        </div>-->


      </app-block-dashboard>
    </div>
  </div>
</ng-container>

import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DeviceEditQuickComponent } from 'src/app/modals/device-edit-quick/device-edit-quick.component';
import { InputDateModalComponent } from 'src/app/modals/input-date-modal/input-date-modal.component';
import { InputTextModalComponent } from 'src/app/modals/input-text-modal/input-text-modal.component';
import { SearchDeviceModelModalComponent } from 'src/app/modals/search-device-model-modal/search-device-model-modal.component';
import { SearchUserModalComponent } from 'src/app/modals/search-user-modal/search-user-modal.component';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { Device, Information, vDevice, vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User, vUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-device',
  templateUrl: './detail-device.component.html',
  styleUrls: ['./detail-device.component.scss']
})
export class DetailDeviceComponent implements OnInit {
  @Input() device?: Device;
  @Input() pageInput?: string = "";
  @Input() idAlert?: number;
  loading: boolean = false;
  currentIndex: number = 0;
  chartLoad: boolean = false;

  groupTab: string[] = [
    'general',
    'grafica',
    'notificaciones',
    'alarmas',
    'cliente',
    'fabricante',
    'datos'
  ];

  constructor(
    public dialog: MatDialog,
    private deviceSrv: DevicesService,
    private breadcrumbService: BreadcrumbService,
    private router : Router,
    private utilsSrv: UtilsService,
    public authSrv: AuthenticationService,
    private translate: TranslateService) { }


  get showTrips(): boolean{
    return this.authSrv.isAdmin() || ( environment.showTrips && this.authSrv.hasTripsModuleEnabled());
  }
  /**
   * Cambiamos el signo cuando el GMT pasa de 0  a -1
   */
  get longitudeWGS84(): number | null{
    if(this.lastInformationSensors?.TrackPosition?.LongitudeWGS84){
      if( this.lastInformationSensors?.TrackPosition?.LongitudeDirection == 'E'){
        return this.lastInformationSensors?.TrackPosition?.LongitudeWGS84;
      }
      if(this.lastInformationSensors?.TrackPosition?.LongitudeWGS84 < 0){
        return this.lastInformationSensors?.TrackPosition?.LongitudeWGS84;
      }
      return 0-this.lastInformationSensors?.TrackPosition?.LongitudeWGS84;
    }

    return null;
  }

  /**
   * Cambiamos el signo cuando el la posicio esta por denajo del ecuador.
   */
  get latitudeWGS84(): number | null{
    if(this.lastInformationSensors?.TrackPosition?.LatitudeWGS84){
      if( this.lastInformationSensors?.TrackPosition?.LatitudeDirection == 'N'){
        return this.lastInformationSensors?.TrackPosition?.LatitudeWGS84;
      }
      if(this.lastInformationSensors?.TrackPosition?.LatitudeWGS84<0){
        return this.lastInformationSensors?.TrackPosition?.LatitudeWGS84;
      }
      return 0-this.lastInformationSensors?.TrackPosition?.LatitudeWGS84;
    }

    return null;
  }

  get isAdmin(): boolean{
    return this.authSrv.isAdmin();
  }

  ngOnInit(): void {

    if(this.authSrv.isAdmin() == false){
      this.groupTab.splice(this.groupTab.indexOf('datos'),1);
    }

    /*if(this.lastInformationSensors == null){
      this.groupTab.splice(this.groupTab.indexOf('grafica'),1);
    }*/

    if(this.authSrv.isAdmin() == false && this.authSrv.isManufacturer() == false){
      this.groupTab.splice(this.groupTab.indexOf('cliente'),1);
    }
    if(this.authSrv.isAdmin() == false && this.authSrv.isClient() == false){
      this.groupTab.splice(this.groupTab.indexOf('fabricante'),1);
    }

    if(this.pageInput! != ""){
      console.log(this.groupTab, this.pageInput, this.groupTab.indexOf(this.pageInput!));
      this.currentIndex  =  this.groupTab.indexOf(this.pageInput!);
      /*setTimeout(() => {
        this.currentIndex  =  this.groupTab.indexOf(this.pageInput!);
      }, 500);*/
    }



    if(this.device){
      let nombre = this.device?.Name??this.translate.instant('modules.devices.no-name');
      let imei = this.device?.IMEI??'';
      let title: string = this.translate.instant('modules.devices.device');
      this.breadcrumbService.addBreadCrumb('devices/' + this.device!.Id??''  + '/detail', title + ' ' + nombre + ' | ' + imei  );
    }
    //this.breadcrumbService.addBreadCrumb('devices/' + this.device!.Id??''  + '/detail', this.translate.instant('modules.devices.device') + this.device!.Name??'' + ' | ' + this.device!.IMEI??'' );

    if(this.pageInput == "grafica"){
      this.chartLoad = true;
    }

  }

  get lastInformationSensors(): null | Information {
    if(this.device && this.device.Informations && this.device.Informations.length > 0){
      return this.device.Informations[this.device.Informations.length -1 ];
    }
    return null;
  }

  goEditDevice(){
    this.router.navigate(['/devices/' + this.device!.Id  + '/detail']);
  }

  changeUser(typeUser: string, inputUser?: vUser){

    let title = (typeUser == 'user')?this.translate.instant('modules.users.add-user'):this.translate.instant('modules.account.add-account');
    let title2 = (typeUser == 'user')?this.translate.instant('modules.users.change-user'):this.translate.instant('modules.account.change-account');
    let BtnAccept = this.translate.instant('modules.users.change');

    const dialogRef = this.dialog.open(
      SearchUserModalComponent,
      {
        id: 'SearchUserModalComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
      data: (inputUser)?new Modal({ 'Title' : title2, 'Data' : typeUser, 'BtnAccept': BtnAccept, 'User' : { ...inputUser } }):new Modal({ 'Title' : title, 'Data' : typeUser}),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });


    dialogRef.afterClosed().subscribe({
      next: (result?: Array<vUser>) => {
      if(result){
        console.log(result);
        if(typeUser == 'user'){
          // Crear relación y recuperar usuario
          this.device!.Client = new User(result[0]);
          this.device!.IdUser = this.device!.Client.Id;
        }else if(typeUser == 'fabricant'){
          // Crear relación y recuperar usuario
          this.device!.Manufacturer = new User(result[0]);
        }

        this.loading = true;

        if(!this.device!.NumSerieManufacturer){
          this.device!.NumSerieManufacturer = ""
        }
        if(!this.device!.Comments){
          this.device!.Comments = ""
        }

        this.deviceSrv.change(this.device!).subscribe({
          next: (resultDevice: Device) =>{

            console.log(resultDevice);
            this.device = new Device(resultDevice);

          }, complete: () =>{
            this.loading = false;
          }
        });
      }
    },error: (res: any) =>{
      console.log(res);

    }
    });

  }


  changeModel(typeUser: string, inputUser?: vUser){

    let title = this.translate.instant('modules.devices.add-account-model');
    let title2 = this.translate.instant('modules.devices.change-model');

    const dialogRef = this.dialog.open(
      SearchDeviceModelModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        minHeight: '500px',
        data: (inputUser)?new Modal({ 'Title' : title2, 'User' : { ...inputUser }, 'Data' : { ...this.device }}):new Modal({ 'Title' : title, 'Data' : { ...this.device }}),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe({
      next: (result?: Array<Device>) => {

      console.log("detail device component patch");
      if(result){
        console.log(result[0]);
        console.log(this.device);

        this.device!.IdModel = result[0].Id!;
        this.device!.DeviceModel = result[0];

        this.loading = true;


        if(!this.device!.NumSerieManufacturer){
          this.device!.NumSerieManufacturer = ""
        }
        if(!this.device!.Comments){
          this.device!.Comments = ""
        }

        this.deviceSrv.change(this.device!).subscribe({
          next: (resultDevice: Device) =>{

            console.log(resultDevice);
            this.device = new Device(resultDevice);

          }, complete: () =>{
            this.loading = false;
          }
        });
      }
    },error: (res: any) =>{
      console.log(res);

    }
    });

  }

  onTabChanged($event: MatTabChangeEvent) {
    console.log("new index" , $event.index);

    let index = $event.index;
    const that = this;
    if($event.index == 1){
      setTimeout(() => {
        try{
          console.log("new index 2" , index);
          that.currentIndex  =  index;
          that.chartLoad = true;

        }catch(ex){
          console.log("ex" , ex);
          setTimeout(() => {
            console.log("new index 3" , index);
            that.currentIndex  =  index;
            that.chartLoad = true;
          }, 3000);

        }
      }, 500);
    }else{
      this.currentIndex = index;
    }

  }


  editDevice()
  {
    let data;

    data = {'Data' : { 'fromList' : true  }};

    if(this.device?.Manufacturer){
      data = { 'Data' : { "Device" : { ...this.device } , 'idUser': this.device?.Manufacturer.Id, "User" : { ...this.device?.Manufacturer } }  } ;
    }


    console.log("data", data);

    const dialogRef = this.dialog.open(
      DeviceEditQuickComponent,
      {
        id: 'DeviceEditQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal( data ),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vDevice) => {
      if(result){

        console.log("Guardar modificacions maquina");
        console.log(result);
        this.loadDevice(result.Id!);



      }

    });
  }


  
  loadDevice(id: number){
    this.loading = true;
    console.log('loadDevice: ' , id );
    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        this.device = <Device>new Device(res);
        console.log(this.device );
        this.loading = false;
      }, error: (err: ApiRequestObject)=> {
        this.loading = false;
      }
    });
  }

  cleanDevice(){
    let modal = new Modal();
    modal.Title = 'Limpiar dispositivo';
    modal.Body = 'Seguro que quiere eliminar los datos del dispositivo con IMEI ' + this.device?.IMEI + ' ? <br>Puede seleccionar una fecha para borrar solo los datos anteriores a esa fecha:';
    modal.BtnAccept = 'Limpiar';
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      InputDateModalComponent,
      {
      autoFocus : 'dialog',
      width: "550px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: any) => {

        if(result == ''){
          console.log('Cancelar');
          return;
        }

        let dateFrom: DateTime = DateTime.now().plus({day: 1});
        if(result){
          console.log("Borrado de antes de: " + result.toFormat("dd/MM/yyyy"));
          dateFrom = result;

        }else{
          console.log("Borrado de todo");

        }

        console.log(":: Borrado: " + dateFrom.toFormat("dd/MM/yyyy"));
        this.loading = true;

        this.deviceSrv.cleanByImei(this.device!.IMEI! , dateFrom!.toISODate()).subscribe(
          {
            next: (res: boolean) => {
              if(res){
                // this.router.navigate(['/devices/' + this.device!.Id!  ]);
                this.loadDevice(this.device!.Id!);
              }else{

                this.utilsSrv.infoModal('Error limpiar dispositivo.',undefined);
              }

            },
            error: (err) =>{
              this.loading = false;
              this.utilsSrv.infoModal('Error limpiar dispositivo.',undefined);
    
            }
          }
        );

   
      }
    );

  }

  migrateDevice(){
    let modal = new Modal();
    modal.Title = 'Migrar datos dispositivo';
    modal.Body = 'Seguro que quiere migrar los datos del dispositivo con IMEI ' + this.device?.IMEI + ' ?<br> Introduzca el IMEI del dispositvo donde se van a copiar:';
    modal.BtnAccept = this.translate.instant('modules.modals.continue');
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      InputTextModalComponent,
      {
      autoFocus : 'dialog',
      width: "550px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string) => {

        if(result == ''){
          console.log('Cancelar');
          return;
        }

        this.loading = true;

        this.deviceSrv.migrateByImei(this.device!.IMEI! , result).subscribe(
          {
            next: (res: boolean) => {
              if(res){
                // this.router.navigate(['/devices/' + this.device!.Id!  ]);
                this.loadDevice(this.device!.Id!);
              }else{

                this.utilsSrv.infoModal('Error al migrar datos.',undefined);
              }

            },
            error: (err) =>{
              this.loading = false;
              this.utilsSrv.infoModal('Error al migrar datos.',undefined);
    
            }
          }
        );
      }
        
    );
  }

}

